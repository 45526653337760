%app-icon {
    align-items: center;
    background-color: $white;
    display: flex;
    justify-content: center;

    .app-icon__image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
    }
}

%description {
    font-size: 12px;
    font-weight: normal;
}

%label {
    color: $darkestGrey;
    font-size: 14px;
    font-weight: 700;
}

%ui-btn {
    background-color: $mediumColor;
    color: $white;
    letter-spacing: 1px;

    &:focus,
    &:hover {
        background-color: $darkColor;
        outline: 0;
    }

    &:disabled {
        background-color: $mediumGrey;
    }
}
