/* stylelint-disable no-descending-specificity */
.createAccount {
    background-color: $white;

    &-header {
        background-color: var(--primaryColor);
        color: var(--primaryForeground);
        padding: 32px 16px 0;
        position: relative;

        > svg {
            fill: var(--primaryForeground);
            left: 0;
            position: absolute;
            top: 2px;
            width: 100%;
            z-index: 1;
        }

        h1 {
            font-size: 20px;
            font-weight: 500;
        }

        div {
            align-items: center;
            display: flex;
            justify-content: space-between;

            svg {
                fill: var(--primaryForeground);
                height: 24px;
                max-width: 24px;
            }
        }
    }

    &-formWrap {
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 30px;
        grid-template-columns: 1fr 1fr;
        margin-top: 8px;
        padding: 16px;

        button {
            grid-column: span 2;
            margin-top: 24px;
            width: 100%;
        }

        .fakeInput {
            border-bottom: 1px solid black;
            color: black;
            grid-column: span 2;
            padding: 8px 0;

            &.half {
                grid-column: span 1;
            }

            &.icon {
                display: flex;
                justify-content: space-between;

                img {
                    height: 16px;
                    width: 16px;
                }
            }
        }

        & .toggleRow {
            color: black;
            display: flex;
            grid-column: span 2;
            justify-content: space-between;
            position: relative;

            p {
                font-size: 14px;
                max-width: 80%;

                span {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }

            .toggle {
                position: relative;

                &-circle {
                    background-color: $white;
                    border-radius: 100%;
                    box-shadow: 1px 2px 6px rgba($black, 0.4);
                    height: 24px;
                    position: absolute;
                    right: 16px;
                    top: 0;
                    width: 24px;
                    z-index: 1;
                }

                &-track {
                    background-color: rgba($black, 0.2);
                    border-radius: 16px;
                    height: 18px;
                    position: absolute;
                    right: 0;
                    top: 3px;
                    width: 40px;
                }
            }
        }
    }
}

/* stylelint-enable no-descending-specificity */
