.visually-hidden {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
}

.hidden {
    display: none;
}

.nowrap {
    white-space: nowrap;
}
