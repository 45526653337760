/* stylelint-disable no-descending-specificity */
.schedule {
    &-header {
        background-color: var(--primaryColor);
        color: var(--primaryForeground);
        height: 200px;
        padding: 24px;
        position: relative;

        > svg {
            fill: var(--primaryForeground);
            left: 0;
            position: absolute;
            top: 3px;
            width: 100%;
        }

        .region-row {
            display: flex;
            justify-content: space-between;
            margin: 32px 0;

            span {
                position: relative;

                svg {
                    fill: var(--primaryForeground);
                    height: 18px;
                    margin-left: 6px;
                    position: relative;
                    top: 2px;
                    width: 18px;
                }
            }

            > svg {
                fill: var(--primaryForeground);
                height: 32px;
                width: 32px;
            }
        }

        .schedule-row {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            position: relative;
            text-align: center;
            z-index: 2;

            h2 {
                font-size: 26px;
                letter-spacing: -1px;
                margin: 0;
            }

            p {
                font-size: 11px;
                font-weight: 100;
                letter-spacing: 0;
                line-height: 0.2;
                margin: 0;
                text-transform: uppercase;
            }

            .schedule-selected {
                color: black;

                &::before {
                    background-color: $white;
                    border-radius: 100%;
                    content: "";
                    height: 52px;
                    left: -2px;
                    position: absolute;
                    top: -2px;
                    width: 52px;
                    z-index: -1;
                }
            }
        }

        #region {
            font-size: 26px;
            font-weight: 500;
        }
    }

    &-content {
        &-date {
            font-size: 15px;
            font-weight: 100;
            line-height: 1;
            padding: 16px;
        }

        &-item {
            background-color: $white;
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: 1fr 3fr;
            margin-bottom: 2px;
            padding: 14px 16px;

            &_left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &_right {
                display: grid;
                grid-column-gap: 16px;
                grid-template-rows: auto auto;

                &_bottom {
                    align-items: flex-end;
                    display: flex;
                    justify-content: space-between;

                    .label-4 {
                        margin-top: 4px;
                    }
                }
            }

            & img {
                border: 1px solid $lightestGrey;
                border-radius: 100%;
                height: 50px;
                object-fit: cover;
                overflow: hidden;
                width: 50px;
            }
        }
    }

    &.view-android {
        &-header {
            height: 148px;
            padding: 16px;

            & .region-row {
                margin: 16px 0;
            }

            #region {
                font-size: 22px;
            }
        }

        .navBar {
            bottom: 16px;
        }

        &-content {
            position: relative;

            &-filter {
                align-items: center;
                background-color: var(--secondaryColor);
                border-radius: 116px;
                bottom: 87px;
                box-shadow: 0 4px 10px rgba($black, 0.3);
                color: var(--secondaryForeground);
                display: flex;
                font-size: 14px;
                font-weight: 500;
                height: 44px;
                justify-content: center;
                left: 0;
                letter-spacing: 0.5px;
                margin: auto;
                position: absolute;
                right: 0;
                text-transform: uppercase;
                width: 118px;

                svg {
                    fill: var(--secondaryForeground);
                    height: 20px;
                    margin-right: 8px;
                    position: relative;
                    width: 20px;
                }
            }
        }
    }
}

.view-schedule {
    .navBar > img {
        filter: grayscale(100%);
    }

    .icon-schedule {
        fill: $black;
    }
}

/* stylelint-enable no-descending-specificity */
