$darkestGrey: #31393b;
$darkGrey: #404040;
$mediumGrey: #757475;
$lightGrey: #babec2;
$lightestGrey: #d7d9db;

$offwhite: #f4f4f4;
$white: white;
$red: red;
$black: black;

$lightColor: #99dede;
$mediumColor: #00787a;
$darkColor: #006164;

$font-family: avenir, "Segoe UI", roboto, "Helvetica Neue", ubuntu, sans-serif;
