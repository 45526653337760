.preview {
    background: rgba($lightestGrey, 0.3);
    overflow: scroll auto;
    padding: 32px;

    &__wrapper {
        transform: scale(0.23);
        transform-origin: top left;
    }

    &--hidden {
        left: -9999px;
        position: absolute;
        top: -9999px;
    }

    .preview__row {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(7, 1fr);
        width: min-content;

        + .preview__row {
            margin-top: 32px;
        }

        .export {
            &-iphoneX {
                height: 2688px;
                width: 1242px;
            }

            &-iphone8 {
                height: 2208px;
                width: 1242px;
            }

            &-android {
                height: 1656px;
                width: 828px;
            }

            &-androidplaygraphic {
                height: 500px;
                width: 1024px;
            }
        }
    }
}
