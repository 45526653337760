/* stylelint-disable no-descending-specificity */
.welcome {
    background-color: $white;
    display: grid;
    grid-template-rows: auto 280px;

    &-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 32px;
        position: relative;
        text-align: left;

        svg {
            fill: black;
            left: 0;
            position: absolute;
            top: 4px;
            width: 100%;
        }

        .clientMark {
            max-height: 64px;
            max-width: 80px;
        }

        h1 {
            font-size: 40px;
            line-height: 1.1;
            margin: 16px 0;
        }

        p {
            font-size: 15px;
            line-height: 1.4;
            max-width: 240px;
        }

        &-skip {
            font-size: 15px;
            font-weight: 500;
            position: absolute;
            right: 24px;
            top: 54px;

            img {
                height: 16px;
                position: relative;
                top: 3px;
                width: 16px;
            }
        }
    }

    &-bottom {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        position: relative;
        z-index: 1;

        &-poweredBy {
            height: auto;
            margin-top: 40px;
            width: 108px;
        }

        > .colorAngle-bg {
            background-color: var(--primaryColor);
            height: 120%;
            left: -30px;
            position: absolute;
            top: 0;
            transform: rotate(-4deg);
            transform-origin: top left;
            width: 120%;
            z-index: -1;
        }
    }
}

/* stylelint-enable no-descending-specificity */
