.divider {
    border-bottom: 1px solid $lightGrey;
    border-top: 1px solid $lightGrey;
    margin-bottom: 30px;
    opacity: 0.5;

    &--light {
        margin-bottom: 20px;
        opacity: 0.2;
    }
}
