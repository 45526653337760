.btn-link {
    color: $black;
    font-weight: 500;

    &:focus,
    &:hover {
        color: $darkColor;
        outline: 0;
    }

    img {
        margin: 8px;
    }

    &--small {
        color: $darkColor;
        font-size: 12px;
        text-decoration: underline;

        &:focus,
        &:hover {
            color: $black;
        }
    }
}
