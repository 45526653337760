.btn-tab {
    @extend %ui-btn;

    border-radius: 6px 6px 0 0;
    box-shadow: 5px 0 10px 0 rgba($darkGrey, 0.3);
    height: 28px;
    padding: 0 20px;
    position: absolute;
    right: -53px;
    top: 40px;
    transform: rotate(90deg);
}
