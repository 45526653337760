.toggle {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-top: 6px;

    & input {
        background: none;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;

        + .toggle__wrapper {
            background-color: $white;
            background-position-x: calc(100% - 8px);
            background-position-y: center;
            background-repeat: no-repeat;
            background-size: 8px;
            border: 1px solid $lightestGrey;
            border-radius: 15px;
            display: inline-block;
            height: 25px;
            position: relative;
            width: 46px;

            > .toggle__indicator {
                align-items: center;
                background-color: $lightestGrey;
                border: 1px solid $lightGrey;
                border-radius: 50%;
                display: flex;
                height: 20px;
                justify-content: center;
                position: absolute;
                top: 50%;
                transform: translate(2px, -50%);
                transition:
                    background-color ease 0.2s,
                    transform ease 0.2s;
                width: 20px;
            }

            &--on {
                background-position-x: 8px;

                > .toggle__indicator {
                    background-color: $mediumColor;
                    border: 1px solid $mediumColor;
                    transform: translate(22px, -50%);
                }
            }

            &::after {
                border-color: transparent;
                border-radius: 15px;
                border-style: solid;
                border-width: 2px;
                content: " ";
                height: calc(100% + 4px);
                left: -2px;
                position: absolute;
                top: -2px;
                transition: border-color ease 0.2s;
                width: calc(100% + 4px);
            }

            + .toggle__state-description {
                margin-left: 8px;
            }
        }

        &:hover + .toggle__wrapper::after,
        &:focus + .toggle__wrapper::after {
            border-color: $lightColor;
        }
    }
}
