.progress {
    &__backdrop {
        align-items: center;
        background-color: rgba($black, 0.3);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 1000;
    }

    &__modal {
        align-items: center;
        background-color: $white;
        border-radius: 4px;
        box-shadow: 12px 12px 32px rgba($black, 0.3);
        display: flex;
        flex-direction: column;
        height: 213px;
        justify-content: center;
        padding: 32px;
        width: 430px;
    }

    &-bar {
        background-color: $lightestGrey;
        border-radius: 8px;
        height: 8px;
        margin-top: 24px;
        width: 92%;

        &__indicator {
            background-color: $mediumColor;
            border-radius: 8px;
            color: $white;
            height: 100%;
            padding: 0 16px;
        }
    }
}
