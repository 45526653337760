/* stylelint-disable no-descending-specificity */
.achievements {
    background-color: $white;
    position: relative;

    &-header {
        align-items: center;
        background-color: var(--secondaryColor);
        display: flex;
        height: 56%;
        justify-content: center;
        position: relative;

        > svg {
            fill: var(--secondaryForeground);
            height: 15px;
            position: absolute;
            right: 24px;
            top: 40px;
            width: 15px;
        }

        div {
            align-items: center;
            background-color: $white;
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            height: 300px;
            justify-content: center;
            position: relative;
            text-align: center;
            width: 300px;

            > img {
                margin-bottom: 8px;
                max-height: 58px;
                max-width: 58px;
            }

            h1 {
                font-size: 80px;
                line-height: 1.2;
                margin: 0;

                & span {
                    letter-spacing: -6px;
                }
            }

            p {
                color: $darkGrey;
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                max-width: 181px;
            }
        }
    }

    & .tileRow {
        margin-top: -48px;

        & .tile {
            height: 130px;

            & img {
                margin-bottom: 8px;
                max-height: 40px;
                max-width: 48px;
            }

            h2 {
                font-size: 40px;
                letter-spacing: -2px;
                line-height: 1.1;
            }

            h3,
            text.h3 {
                fill: $black;
                font-size: 17px;
                font-weight: 700;
                line-height: 1.8;
                margin: 0 0 4px 0;
            }

            p {
                font-size: 15px;
                line-height: 1.2;
            }

            &-visited {
                div {
                    display: flex;
                    margin-top: 4px;
                }

                h2 {
                    margin-right: 12px;
                }

                p {
                    max-width: 40px;
                }
            }
        }
    }

    & .button-row {
        padding: 24px 16px;

        button {
            font-size: 17px;
            width: 100%;
        }
    }

    .view-android & {
        &-header {
            height: 58.5%;

            img {
                top: 24px;
            }

            & div {
                height: 280px;
                margin-top: -28px;
                width: 280px;

                h1 {
                    line-height: 1;
                }
            }
        }
    }

    .screenshot-iphone8 & {
        &-header {
            height: 58.5%;

            > img {
                right: 24px;
                top: 24px;
            }

            & div {
                height: 280px;
                margin-top: -28px;
                width: 280px;

                h1 {
                    line-height: 1;
                }
            }
        }
    }
}

/* stylelint-enable no-descending-specificity */
