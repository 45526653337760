:root {
    --primaryColor: #000;
    --secondaryColor: #7833ff;
    --primaryForeground: #fff;
    --secondaryForeground: #fff;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
}

body,
input,
button,
select,
textarea {
    font-family: $font-family;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
    color: $lightGrey;
}

button {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    transition:
        background-color 0.2s ease,
        color 0.2s ease,
        fill 0.2s ease,
        stroke 0.2s ease;
}
