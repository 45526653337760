.view .tileRow {
    column-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: -24px;
    padding: 0 16px;
    position: relative;
    row-gap: 12px;
    z-index: 1;

    .tile {
        align-items: center;
        background-color: $white;
        border: 1px solid $lightestGrey;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        height: 120px;
        justify-content: center;
        padding: 4px;
        text-align: center;
    }
}
