.view {
    background-color: $offwhite;
    clip-path: url(#phoneClip);
    height: 812px;
    overflow: hidden;
    position: relative;
    width: 375px;

    h2,
    h3,
    p {
        margin: 0;
    }
}
