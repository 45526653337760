.view {
    .label-1 {
        font-size: 15px;
        font-weight: 700;
    }

    .label-2 {
        font-size: 13px;
        font-weight: 400;
    }

    .label-3 {
        font-size: 13px;
        font-weight: 700;
    }

    .label-4 {
        color: $lightGrey;
        font-size: 11px;
        font-weight: 300;
        line-height: 1;
    }
}
