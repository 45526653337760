/* stylelint-disable no-descending-specificity */
.form {
    height: 100vh;
    overflow-y: scroll;

    &-header {
        align-items: center;
        box-shadow: 5px 0 12px rgba($black, 0.15);
        display: flex;
        padding: 30px;

        .logo {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 32px;
            margin-right: 20px;
            width: 32px;
        }

        h1 {
            color: $black;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            margin: 0;
            opacity: 1;
            text-align: left;
            white-space: nowrap;
        }
    }

    &-wrap {
        padding: 30px 20px;
    }

    &-section {
        margin-bottom: 40px;

        .section-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            margin: 0 0 24px;
        }

        .subsection-title {
            font-size: 24px;
            font-weight: normal;
            line-height: 30px;
            margin: 0 0 16px;

            &--optional {
                &::after {
                    color: $mediumGrey;
                    content: '(optional)';
                    display: inline-block;
                    font-size: 16px;
                    margin-left: 4px;
                }
            }
        }

        &__description {
            @extend %description;

            color: $black;
            margin: -8px 0 16px;
        }
    }

    &-row {
        display: flex;
        margin-bottom: 20px;
    }

    &-field {
        width: 100%;

        + .form-field {
            margin-left: 20px;
        }

        &__label {
            @extend %label;

            margin-bottom: 6px;
        }

        &__description {
            @extend %description;

            color: $mediumGrey;
            margin-bottom: 6px;
        }

        &__error {
            color: $red;
            font-size: 14px;
            font-weight: 100;
            text-align: left;
        }

        &__input {
            position: relative;

            &-element {
                border: 2px solid $lightestGrey;
                border-radius: 4px;
                font-size: 16px;
                padding: 12px 16px;
                width: 100%;

                &--error {
                    border: 1px solid $red;
                }

                &:focus {
                    border: 2px solid $mediumColor;
                    outline: none !important;
                }
            }
        }

        &--color {
            & .btn-color {
                border: 1px solid $lightestGrey;
                border-radius: 5px;
                cursor: pointer;
                height: 32px;
                left: 8px;
                padding: 0;
                position: absolute;
                top: 8px;
                width: 32px;
                z-index: 1;

                &:focus {
                    border-color: $mediumColor;
                    outline: none !important;
                }

                &::after {
                    align-items: center;
                    color: $black;
                    content: '#';
                    display: flex;
                    font-family: $font-family;
                    font-size: 16px;
                    font-weight: 400;
                    height: 100%;
                    line-height: 0;
                    position: absolute;
                    right: -20px;
                    top: 1px;
                }
            }

            .form-field__input-element {
                padding-left: 60px;

                &:invalid + .btn-color::after {
                    color: $lightGrey;
                }
            }
        }
    }

    &-image-drop {
        align-items: center;
        border: 2px dashed $lightestGrey;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 80px;
        justify-content: space-between;
        padding: 8px;
        text-align: left;
        width: 100%;

        &:hover {
            border-color: $mediumColor;
        }

        &--error {
            border: 2px dotted $red;
        }

        &__label {
            @extend %label;

            margin: auto;
            padding: 0 16px 0 8px;

            p {
                margin: 0;
            }

            .highlight {
                color: $mediumColor;
                font-weight: 600;
            }
        }

        &__input {
            display: none;
        }

        &__preview {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 65%;
            border-radius: 3px;
            box-shadow: 3px 3px 7px rgba($black, 0.15);
            color: transparent;
            height: 100%;
            opacity: 1;
            position: relative;
            width: 90px;
        }
    }

    .btn-remove {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: transparent;
        height: 26px;
        opacity: 1;
        position: relative;
        width: 26px;

        &:focus {
            outline: 0;
        }
    }
}
/* stylelint-enable no-descending-specificity */
