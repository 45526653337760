/* stylelint-disable no-descending-specificity */
.pas {
    background-color: $white;

    &-header {
        background-color: var(--primaryColor);
        color: var(--primaryForeground);
        padding: 32px 16px 0;
        position: relative;

        > svg {
            fill: var(--primaryForeground);
            left: 0;
            position: absolute;
            top: 0;
        }

        h1 {
            font-size: 26px;
            font-weight: 500;
        }

        &-titleRow {
            align-items: center;
            display: flex;
            justify-content: space-between;

            svg {
                fill: var(--primaryForeground);
                height: 24px;
                max-width: 24px;
            }
        }
    }

    &-layout {
        background-color: $white;
        padding: 16px;

        &-row {
            column-gap: 8px;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            margin-bottom: 8px;

            div {
                align-items: center;
                background-color: $white;
                border: 1px solid $black;
                border-radius: 100%;
                display: flex;
                font-size: 13px;
                font-weight: 700;
                justify-content: center;
                line-height: 0;
                padding: 50%;
                width: 100%;

                &.hidden {
                    opacity: 0;
                }

                &.active {
                    background-color: var(--secondaryColor);
                    border-color: var(--secondaryColor);
                    color: var(--secondaryForeground);
                    position: relative;
                    transform: scale(0.8);

                    &::after {
                        border: 2px solid var(--secondaryColor);
                        border-radius: 50%;
                        content: "";
                        height: 100%;
                        position: absolute;
                        transform: scale(1.4);
                        width: 100%;
                    }
                }

                &.inactive {
                    background-color: $white;
                    background-image:
                        repeating-linear-gradient(
                            45deg,
                            transparent,
                            transparent 1.5px,
                            $lightestGrey 1px,
                            $lightestGrey 3px
                        );
                    background-size: 11% 11%;
                    border-color: transparent;
                }
            }
        }
    }

    .view-android & {
        background-color: $white;

        &-header {
            background-color: var(--primaryColor);

            svg {
                top: 3px;
                width: 100%;
            }
        }

        &-layout-row div {
            border: 1px solid black;

            &.active {
                border-color: var(--secondaryColor);
            }

            &.inactive {
                background-color: $lightestGrey;
                border: none;
            }
        }
    }
}

/* stylelint-enable no-descending-specificity */
