/* stylelint-disable no-descending-specificity */
.screenshot {
    background-color: $white;
    height: 896px;
    overflow: hidden;
    padding: 40px;
    position: relative;

    // scope this later to just export
    transform: scale(3);
    transform-origin: top left;

    // This is a .33 scale of the final dimensions
    width: 414px;

    .phoneMock {
        left: -206px;
        top: 26px;
        transform: scale(0.23);
        transform-origin: top left;
    }

    .view-home {
        perspective: 1000px;
        perspective-origin: 25% 75%;
        transform: perspective(202em) rotateY(-24deg) rotateX(35deg) rotate(17.5deg) scale(0.8, 0.75);
    }

    &-iphone8 {
        height: 736px;

        & .view {
            clip-path: none;
            height: 667px;
            width: 375px;

            &-home {
                transform: perspective(163em) rotateY(-24deg) rotateX(30deg) rotate(18deg) scale(0.8, 0.72);
            }
        }

        & .phoneMock {
            left: -156px;
            top: -36px;
            transform: scale(0.245);
        }
    }

    &-android {
        // Android starting dimensions 414 x 828
        height: 828px;
        // android final dimensions 828x1656
        transform: scale(2);

        & .clientLogo {
            transform: scale(0.7);
        }

        & .view {
            border-radius: 18px;
            clip-path: none;
            height: 720px;
            width: 360px;

            &-home {
                transform: perspective(343em) rotateY(-33deg) rotateX(13deg) rotate(21.7deg) scale(0.84, 0.76);
            }
        }

        & .phoneMock {
            left: -238px;
            top: -84px;
            transform: scale(0.266);
        }
    }

    .colorAngle {
        background-color: var(--secondaryColor);
        bottom: -230px;
        height: 100%;
        position: absolute;
        right: -300px;
        transform: rotate(33deg);
        width: 100%;
    }

    .phone-outer {
        position: absolute;
        right: -180px;
        top: 20px;
        transform: scale(1.28);
    }

    > h2 {
        color: var(--secondaryForeground);
        font-size: 26px;
        font-weight: 200;
    }
    // First screenshot
    &-1 {
        .clientMark {
            margin-bottom: 16px;
            width: 72px;
        }

        h1 {
            font-size: 36px;
            font-weight: 500;
            line-height: 1.3;
            margin: 0;
            max-width: 268px;
        }

        &.screenshot-android {
            & .phone-outer {
                top: 120px;
            }
        }

        &.screenshot-iphone8 {
            & .phone-outer {
                right: -222px;
                top: 0;
                transform: scale(1.1);
            }
        }
    }

    // Second screenshot
    &-2 {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;

        > h2 {
            max-width: 224px;
            text-align: right;
            z-index: 1;
        }

        .phone-outer {
            right: 240px;
            top: 20px;
        }

        .colorAngle {
            bottom: -230px;
            height: 150%;
            right: -253px;
            width: 150%;
        }

        &.screenshot-android {
            & .phone-outer {
                top: 120px;
            }
        }

        &.screenshot-iphone8 {
            & .phone-outer {
                right: 196px;
                top: 0;
                transform: scale(1.1);
            }
        }
    }

    &-3 {
        background-color: var(--secondaryColor);
        padding: 64px;

        > h2 {
            color: var(--secondaryForeground);
            text-align: center;
        }

        .phone-outer {
            right: 18px;
            top: 159px;
            transform: scale(0.83);
        }

        .phoneMock {
            left: -32px;
            top: -29px;
            transform: scale(0.345);
        }

        &.screenshot-android {
            & .phoneMock {
                left: -98px;
                top: -128px;
                transform: scale(0.34);
            }

            & .phone-outer {
                right: 27px;
                top: 192px;
                transform: scale(0.87);
            }
        }

        &.screenshot-iphone8 {
            & .phoneMock {
                left: -150px;
                top: -157px;
                transform: scale(0.35);
            }

            & .phone-outer {
                top: 177px;
                transform: scale(0.77);
            }
        }
    }

    &-4 {
        background-color: var(--secondaryColor);

        .phone-outer {
            right: -140px;
            top: 180px;
            transform: rotate(-14deg) scale(1.1);
        }

        .phoneMock {
            left: -32px;
            top: -26px;
            transform: scale(0.345);
        }

        &.screenshot-android {
            & .phoneMock {
                left: -98px;
                top: -135px;
                transform: scale(0.34);
            }

            & .phone-outer {
                right: -140px;
                top: 195px;
            }
        }

        &.screenshot-iphone8 {
            & .phoneMock {
                left: -150px;
                top: -161px;
                transform: scale(0.35);
            }

            & .phone-outer {
                right: -140px;
                top: 195px;
            }
        }
    }

    &-5 {
        background-color: var(--secondaryColor);
        display: flex;
        justify-content: flex-end;
        padding-top: 64px;

        > h2 {
            margin-left: 100px;
            max-width: 250px;
            text-align: left;
        }

        .phone-outer {
            right: 281px;
            top: 180px;
            transform: rotate(-14deg) scale(1.1);
        }

        .phoneMock {
            left: -34px;
            top: -24px;
            transform: scale(0.345);
        }

        &.screenshot-android {
            & .phoneMock {
                left: -98px;
                top: -135px;
                transform: scale(0.34);
            }

            & .phone-outer {
                right: 282px;
                top: 195px;
            }
        }

        &.screenshot-iphone8 {
            & .phoneMock {
                left: -150px;
                top: -161px;
                transform: scale(0.35);
            }

            & .phone-outer {
                right: 281px;
                top: 195px;
            }
        }
    }

    &-6 {
        background-color: $white;
        padding-top: 64px;

        > h2 {
            color: black;
            text-align: center;
        }

        .phone-outer {
            right: 18px;
            top: 170px;
            transform: scale(0.83);
        }

        .phoneMock {
            left: -34px;
            top: -27px;
            transform: scale(0.345);
        }

        &.screenshot-android {
            & .phoneMock {
                left: -98px;
                top: -128px;
                transform: scale(0.34);
            }

            & .phone-outer {
                right: 27px;
                top: 224px;
                transform: scale(0.84);
            }
        }

        &.screenshot-iphone8 {
            & .phoneMock {
                left: -150px;
                top: -157px;
                transform: scale(0.35);
            }

            & .phone-outer {
                top: 183px;
                transform: scale(0.73);
            }
        }
    }
}

#AppIcon {
    @extend %app-icon;

    height: 1024px;
    width: 1024px;
}

#AppPlayStore {
    background-color: $white;
    height: 500px;
    padding: 0;
    position: relative;
    transform: none;
    transform-origin: top left;
    width: 1024px;
    z-index: 1;

    .image-outer {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 464px;

        > img {
            max-height: 276px;
            max-width: 410px;
        }
    }

    & .phoneMock {
        left: -99px;
        top: -127px;
        transform: scale(0.34);
    }

    & .phone-outer {
        box-shadow: 28px 18px 40px rgba($black, 0.4);

        &-1 {
            right: 246px;
            top: -51px;
            transform: scale(0.6) rotate(10deg);
        }

        &-2 {
            right: -10px;
            top: -68px;
            transform: scale(0.55) rotate(10deg);
        }
    }

    .colorAngle {
        bottom: -80px;
        height: 130%;
        right: -621px;
        transform: rotate(10deg);
        z-index: -1;
    }
}


/* stylelint-enable no-descending-specificity */
