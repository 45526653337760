.color-picker {
    position: absolute;
    top: 10px;
    z-index: 10;

    .form-field--color:nth-child(1) & {
        left: 0;
    }

    .form-field--color:nth-child(2) & {
        right: 0;
    }
}
