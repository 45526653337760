.btn-submit {
    @extend %ui-btn;

    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 6px;
    padding: 12px 16px;
    width: 100%;
}
