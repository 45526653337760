.view {
    .navBar {
        align-items: center;
        background-color: $white;
        border-top: 1px solid $lightestGrey;
        bottom: 0;
        display: flex;
        height: 64px;
        justify-content: space-around;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;

        img {
            height: 31px;
            margin-right: -17px;
            object-fit: contain;
            object-position: center;
            width: 31px;
        }

        svg {
            fill: $lightGrey;
            max-height: 40px;
            max-width: 40px;
        }

        .view-android & {
            bottom: 42px;
            height: 48px;

            img {
                height: 27px;
                margin: 0;
                width: 27px;
            }
        }

        .screenshot-iphone8 & {
            height: 54px;

            .view-schedule & {
                height: 0;
            }
        }
    }
}
