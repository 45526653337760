/* stylelint-disable no-descending-specificity */
.home {
    h3 {
        margin: 8px 0;
    }

    &-header {
        align-items: flex-end;
        background-color: var(--primaryColor);
        color: var(--primaryForeground);
        display: flex;
        height: 240px;
        padding: 24px;
        position: relative;

        h1 {
            display: block;
            font-size: 2em;
            font-weight: bold;
            margin-block-end: 0.67em;
            // not sure why I needed to do this but the prototype was being funky
            margin-block-start: 0.67em;
            margin-inline-end: 0;
            margin-inline-start: 0;
        }

        > svg {
            fill: var(--primaryForeground);
            left: 0;
            position: absolute;
            top: 3px;
            width: 100%;
        }
    }

    .tileRow {
        .tile {
            &:last-child {
                justify-content: space-evenly;
            }

            h2 {
                font-size: 40px;
            }

            p {
                font-size: 15px;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 8px;

                &.👻 {
                    margin-bottom: 11px;
                    margin-top: 25px;
                }
            }

            .label-4 {
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 0;
            }
        }
    }

    &-schedule {
        h3 {
            font-size: 17px;
            font-weight: 500;
            padding: 20px 0 16px 16px;
        }

        .schedule-content-item_checkin {
            .schedule-content-item_right_bottom {
                align-items: flex-start;
                flex-direction: column;

                button {
                    margin-top: 8px;
                    padding: 6px 0;
                    width: 100%;
                }
            }
        }

        .schedule-content-item_left {
            justify-content: flex-start;
        }
    }

    & .navBar {
        .homeIcon {
            fill: $black;
        }
    }

    .view-android & {
        &-header {
            height: 190px;

            svg {
                top: 3px;
                width: 100%;
            }

            h1 {
                line-height: 1.2;
            }
        }

        & .tileRow {
            & .tile {
                border-radius: 4px;
                box-shadow: 0 1px 2px rgba($black, 0.2);
                height: 100px;
            }

            & p.👻 {
                margin-top: 16px !important;
            }
        }
    }

    &.view-ios {
        img {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }

        .iconWrap {
            left: 16px;
            position: absolute;
            text-align: center;
            top: 378px;

            .app-icon {
                @extend %app-icon;

                border-radius: 14px;
                height: 60px;
                margin: 0 10px;
                width: 60px;
            }

            p {
                color: $white;
                font-size: 12px;
                font-weight: 500;
                line-height: 1;
                margin-top: 6px;
                max-width: 80px;
            }
        }
    }

    .screenshot-iphone8 & {
        &-header {
            height: 200px;
        }
    }
}

/* stylelint-enable no-descending-specificity */
