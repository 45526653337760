.view {
    button {
        border-radius: 3px;
        font-family: $font-family;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.2px;
        padding: 10px 0;
        text-align: center;
        width: 85px;

        &:focus {
            outline: 0;
        }

        &.btn-primary {
            background-color: $white;
            border: 2px solid var(--secondaryColor);
        }

        &.btn-secondary {
            background-color: var(--secondaryColor);
            border: 2px solid var(--secondaryColor);
            color: var(--secondaryForeground);
        }

        &.btn-waitlist {
            background-color: $white;
            border: 2px solid $mediumGrey;
            font-size: 11px;
        }

        &.android-buttons {
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }

    &.welcome button {
        background-color: $white;
        border-color: $white;
        border-radius: 5px;
        border-width: 1px;
        color: black;
        font-size: 17px;
        margin-bottom: 8px;
        width: 100%;

        &.btn-primary {
            background-color: transparent;
            border-color: var(--primaryForeground);
            color: var(--primaryForeground);
        }
    }
}
