.view .colorAngle {
    background-color: var(--secondaryColor);
    height: 100%;
    position: absolute;
    right: -110px;
    top: 50%;
    transform: rotate(-6deg);
    width: 140%;
    z-index: -1;
}
