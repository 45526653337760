/* stylelint-disable no-descending-specificity */
@keyframes exit {
    from {
        opacity: 1;
        transform: none;
    }

    to {
        opacity: 0;
        transform: translateX(-400px);
    }
}

.slideshow {
    align-items: center;
    display: contents;
    justify-content: center;
    margin: auto;
    min-width: 1000px;
    position: relative;
    width: 100%;

    &__item {
        left: 0;
        margin-top: 80px;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 100%;

        &__wrapper {
            align-items: center;
            display: flex;
            height: calc(100vh - 200px);
            justify-content: center;
            min-height: 590px;
            overflow: visible;
            position: relative;

            &__background {
                background-color: var(--secondaryColor);
                bottom: 0;
                height: 100%;
                left: -300px;
                position: absolute;
                top: 400px;
                transform: rotate(-5deg);
                width: 200%;
            }

            .screens {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                height: 800px;
                margin-top: 60px;
                max-width: 950px;
                min-width: 800px;
                width: 100%;

                .phone-outer {
                    max-width: none;
                    opacity: 0;
                    overflow: visible;
                    position: relative;
                    top: unset;
                    transform: translateX(400px);
                    transition:
                        opacity 0.4s ease 0.2s,
                        transform 0.4s ease 0.2s;
                    z-index: 1;

                    &:nth-child(2) {
                        transition-delay: 0.23s;
                    }

                    &:nth-child(3) {
                        transition-delay: 0.265s;
                    }

                    .view {
                        height: 812px;
                        left: 0;
                        margin: auto;
                        right: 0;
                        width: 375px;
                    }

                    &.slideshow__item__wrapper-phone1 {
                        right: 7%;

                        .phoneMock {
                            left: -134px;
                            top: -30px;
                            transform: scale(0.519);
                        }

                        .view {
                            background-color: $white;
                            border-radius: 13px;
                            clip-path: none;
                            top: -32px;
                            transform: perspective(216em) rotateY(-32deg) rotateX(-1deg) rotate(0.5deg) scale(0.62, 0.538);
                        }
                    }

                    &.slideshow__item__wrapper-phone2 {
                        right: 24%;
                        z-index: 10;

                        .phoneMock {
                            left: 43px;
                            top: 68px;
                            transform: scale(0.227);
                        }

                        .view {
                            clip-path: url(#phoneClip);
                            top: -54px;
                            transform: scale(0.67);
                        }
                    }

                    &.slideshow__item__wrapper-phone3 {
                        right: 38%;

                        .phoneMock {
                            left: -177px;
                            top: -36px;
                            transform: scale(0.534);
                        }

                        .view {
                            clip-path: url(#phoneClip);
                            top: -52px;
                            transform: perspective(89em) rotateY(34deg) rotateX(1deg) rotate(0.5deg) scale(0.66, 0.58);
                        }
                    }
                }
            }
        }

        &.active {
            visibility: visible;

            .phone-outer {
                opacity: 1;
                transform: none;
            }
        }

        &.inactive {
            .phone-outer {
                animation-duration: 0.2s;
                animation-name: exit;
            }
        }
    }
}

.btn-nav,
.btn-dot {
    transition:
        background-color 0.3s ease,
        opacity 0.3s ease;

    &:focus {
        outline: 0;
    }
}

.slider-nav {
    bottom: 0;
    position: absolute;

    .btn-nav:focus,
    .btn-nav:hover {
        opacity: 0.5;
    }

    .btn-nav {
        background-position: center;
        background-repeat: no-repeat;
        height: 20px;
        width: 30px;
    }

    .btn-dot {
        border-radius: 50%;
        display: inline-block;
        height: 12px;
        margin: 0 8px;
        opacity: 0.3;
        width: 12px;

        &.active,
        &:focus,
        &:hover {
            opacity: 1;
        }
    }
}
/* stylelint-enable no-descending-specificity */
