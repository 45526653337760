main {
    display: grid;
    grid-template-columns: 400px 3fr;
    transition: all 0.5s ease;
    width: 100%;

    &.closedNav {
        transform: translateX(-400px);
        width: calc(100% + 400px);
    }

    .content {
        background-color: $white;
        position: relative;

        &-left {
            box-shadow: 5px 0 10px 0 rgba(50, 50, 50, 0.2);
            height: 100vh;
            position: relative;
            z-index: 100;
        }

        &-right {
            display: flex;
            flex-direction: column;
            height: 100vh;
            overflow: hidden;
            transition: margin-left 0.5s;

            .site-nav {
                justify-content: flex-end;
            }
        }
    }
}
